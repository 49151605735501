exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-consulting-tsx": () => import("./../../../src/pages/consulting.tsx" /* webpackChunkName: "component---src-pages-consulting-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contentful-blog-tsx": () => import("./../../../src/pages/__contentfulBlog.tsx" /* webpackChunkName: "component---src-pages-contentful-blog-tsx" */),
  "component---src-pages-cookie-consent-notice-tsx": () => import("./../../../src/pages/cookie-consent-notice.tsx" /* webpackChunkName: "component---src-pages-cookie-consent-notice-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-full-service-tsx": () => import("./../../../src/pages/full-service.tsx" /* webpackChunkName: "component---src-pages-full-service-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-old-tsx": () => import("./../../../src/pages/indexOld.tsx" /* webpackChunkName: "component---src-pages-index-old-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-knowledge-tsx": () => import("./../../../src/pages/knowledge.tsx" /* webpackChunkName: "component---src-pages-knowledge-tsx" */),
  "component---src-pages-maintenance-tsx": () => import("./../../../src/pages/maintenance.tsx" /* webpackChunkName: "component---src-pages-maintenance-tsx" */),
  "component---src-pages-mdx-tsx": () => import("./../../../src/pages/__mdx.tsx" /* webpackChunkName: "component---src-pages-mdx-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-software-business-tsx": () => import("./../../../src/pages/software-business.tsx" /* webpackChunkName: "component---src-pages-software-business-tsx" */),
  "component---src-pages-software-consult-tsx": () => import("./../../../src/pages/software-consult.tsx" /* webpackChunkName: "component---src-pages-software-consult-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

