module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/var/app/node_modules/gatsby-remark-images","id":"d1396114-0b6d-5cc8-b3cf-6921493101be","name":"gatsby-remark-images","version":"7.12.0","modulePath":"/var/app/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1280,"backgroundColor":"transparent","linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/var/app","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1280,"backgroundColor":"transparent","linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["en","de"],"defaultLanguage":"de","generateDefaultLanguagePage":true,"siteUrl":"https://konfidal.eu","i18nextOptions":{"nonExplicitSupportedLngs":true,"supportedLngs":["en","de"],"fallbackLng":"en","debug":true,"ns":["Default","Header","Home"],"defaultNS":"Default","keySeparator":".","interpolation":{"escapeValue":true,"formatSeparator":","},"detection":{"order":["localStorage","sessionStorage","navigator","path"]},"react":{"defaultTransParent":"span"}},"pages":[{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/privacy","getLanguageFromPath":true},{"matchPath":"/:lang?/terms","getLanguageFromPath":true},{"matchPath":"/:lang?/why","getLanguageFromPath":true},{"matchPath":"/:lang?/free","getLanguageFromPath":true},{"matchPath":"/:lang?/tech","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"aaf15d25904de619fc1d48620e78b345"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"linkedin":{"trackingId":"5493850"},"facebookPixel":{"pixelId":"902419467455710"},"googleTagManager":{"trackingId":"GTM-TSGB9M3Q"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://konfidal.eu","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
