import FrustratedEmployeeIllustration from "../../../../src/images/frustrated-employee-illustration.png";
import SecureIllustration from "../../../../src/images/secure-illustration.png";
import LightningIllustration from "../../../../src/images/lightning-illustration.png";
import MailIllustration from "../../../../src/images/mail-illustration.png";
import TransparencyIllustration from "../../../../src/images/transparency-illustration.png";
import SalesGrowthIllustration from "../../../../src/images/sales-growth-illustration.png";
import ClipboardIllustration from "../../../../src/images/clipboard-illustration.png";
import * as React from 'react';
export default {
  FrustratedEmployeeIllustration,
  SecureIllustration,
  LightningIllustration,
  MailIllustration,
  TransparencyIllustration,
  SalesGrowthIllustration,
  ClipboardIllustration,
  React
};